'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { ErrorBlock } from 'src/components/ErrorBlock';
import { Seo } from 'src/components/Seo';
import { Layout } from 'src/layout';

const NOT_FOUND_DESC =
  'К сожалению, запрашиваемая Вами страница не найдена. Пожалуйста, перейдите в существующий раздел или воспользуйтесь поиском';

export default function NotFoundPage() {
  const seo = {
    title: `Ошибка 404`,
    description: NOT_FOUND_DESC,
  };

  useEffect(() => {
    const transactionName = 'error 404';
    const url = window.location.href;

    Sentry.withScope(function (scope) {
      scope.setTransactionName(transactionName);
      Sentry.setTag('Error 404', url);
      scope.setFingerprint([`error 404 - ${url}`]);
      Sentry.captureException(new Error(`Error404 - ${url}`), scope);
    });
  }, []);

  return (
    <Layout>
      <Seo title={seo.title} />
      <ErrorBlock errorCode={404} />
    </Layout>
  );
}
