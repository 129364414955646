import cn from 'classnames';
import { Button } from 'src/components/Button';

import { Link } from '../Link';
import s from './ErrorBlock.module.scss';

const genErrorTextDescription = (errorCode: number) => {
  if (errorCode === 401)
    return {
      title: 'Unauthorized',
      desc: 'Но есть другие полезные страницы',
      listLinks: [
        { title: 'GitLab для начинающих', href: '/blog/gitlab-about' },
        {
          title: 'Elastic Cloud Server',
          href: '/products/elastic-cloud-server',
        },
        {
          title: 'ML Space',
          href: '/mlspace',
        },
      ],
    };
  if (errorCode === 403)
    return {
      title: 'Доступ запрещен',
      desc: 'Но есть другие полезные страницы',
      listLinks: [
        { title: 'GitLab для начинающих', href: '/blog/gitlab-about' },
        {
          title: 'Elastic Cloud Server',
          href: '/products/elastic-cloud-server',
        },
        {
          title: 'ML Space',
          href: '/mlspace',
        },
      ],
    };
  if (errorCode === 404)
    return {
      title: 'Такой страницы нет',
      desc: 'Но есть другие полезные страницы',
      listLinks: [
        { title: 'GitLab для начинающих', href: '/blog/gitlab-about' },
        {
          title: 'Elastic Cloud Server',
          href: '/products/elastic-cloud-server',
        },
        {
          title: 'ML Space',
          href: '/mlspace',
        },
        {
          title: 'Evolution',
          href: '/evolution',
        },
      ],
    };
  if (errorCode === 500)
    return {
      title: 'Ошибка сервера',
      desc: 'Но есть другие полезные страницы',
      listLinks: [
        { title: 'GitLab для начинающих', href: '/blog/gitlab-about' },
        {
          title: 'Elastic Cloud Server+',
          href: '/products/elastic-cloud-server',
        },
        {
          title: 'ML Space',
          href: '/mlspace',
        },
        {
          title: 'Evolution',
          href: '/evolution',
        },
      ],
    };
  return {
    title: 'Неизвестная ошибка',
    desc: 'Но есть другие полезные страницы',
    listLinks: [
      { title: 'GitLab для начинающих', href: '/blog/gitlab-about' },
      {
        title: 'Elastic Cloud Server+',
        href: '/products/elastic-cloud-server',
      },
      {
        title: 'ML Space',
        href: '/mlspace',
      },
    ],
  };
};

interface ErrorBlockProps {
  errorCode: number;
}

interface DescriptionErrorProps {
  title: string;
  desc: string;
  listLinks: {
    title: string;
    href: string;
  }[];
  button?: boolean;
}

function ArrowLink() {
  return (
    <svg
      className={s.linkArrow}
      data-qa="arrow image"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.11239 15.1644L3.8855 13.9375L12.2084 5.61457H4.93531V3.88541H15.1645V14.1146H13.4353V6.84149L5.11239 15.1644Z"
        fill="#26D07C"
      />
    </svg>
  );
}

export function ErrorBlock({ errorCode = 404 }: ErrorBlockProps) {
  const title = errorCode + '';
  const content: DescriptionErrorProps = genErrorTextDescription(errorCode);

  return (
    <section className={s.root}>
      <h1 className={s.title}>{title}</h1>

      <p className={s.subtitle}>{content.title}</p>
      <p className={s.description}>{content.desc}</p>
      <ul className={s.list}>
        {content.listLinks.map((item, index) => {
          return (
            <li className={s.item} key={index}>
              <Link
                href={item.href}
                className={cn(s.link, 'link')}
                id={`error-block-link-${title}`}
                analyticsOptions={{
                  action: item.href,
                  clickZone: 'body',
                  clickElement: 'textclick',
                  clickContent: item.title,
                  uniqueId: `error-block-link-${title}`,
                  transitionType: 'inside-link',
                }}
              >
                {item.title}
              </Link>
              <ArrowLink />
            </li>
          );
        })}
      </ul>
      {content.button && (
        <Link className="link" href="/" withButton>
          <Button
            id={`error-block-button-lang-${title}`}
            analyticsOptions={{
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: 'Перейти на главную',
              uniqueId: `error-block-button-lang-${title}`,
              transitionType: 'inside-link',
            }}
          >
            Перейти на главную
          </Button>
        </Link>
      )}
    </section>
  );
}
